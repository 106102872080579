import React from "react";
import { useStyles } from "../../styles";
import Loader from './Loader';


export default function LoaderPage () {
  const classes = useStyles();
  return(
    <div className={classes.loaderPageDiv}>
      <Loader />
    </div>
  );
}