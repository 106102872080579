// auth-service.jsx
import React from 'react';
import { setAuthHeader, getResource } from './AxiosService';
import { setUserData, setAccountsData } from './AccountService';

const { createContext, useContext } = React;

const AuthContext = createContext({} as any);

const accessTokenKey = 'access_token';

export const AuthProvider: any = (props: any) => {
  const value = {
    isLoggedIn: props.isLoggedIn || isLoggedIn,
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const isLoggedIn = () => {
  return !!localStorage.getItem(accessTokenKey);
};


const _storeAuthData = (access_token: any, expires_in: any) => {
  var auth = {
    access_token: access_token,
    expires_in: expires_in,
    logged_in: (new Date()).getTime()
  };
  localStorage.setItem(accessTokenKey, access_token);
  localStorage.setItem('auth', JSON.stringify(auth));
  setAuthHeader(`Bearer ${access_token}`);
}

export const authorize = (access_token: any, expires_in: any) => {
  _storeAuthData(access_token, expires_in);
  return loadUserData();
}

const loadUserData = () => {
  return Promise.all([
    getResource('api/user/current'),
    getResource('api/user/current/portal')
  ]).then((res: any) => {
    setUserData(JSON.stringify(res[0]));
    setAccountsData(res[1]);
  })
    .catch((err) => clearUserData());
}

export const checkAuth = (back_uri: any) => {
  back_uri = back_uri || '';
  redirect(
    `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/oauth/v2/auth?`
    + 'client_id=' + (window as any).ApiConfig.client_id
    + '&client_secret=' + (window as any).ApiConfig.client_secret
    + '&response_type=token'
    + '&redirect_uri=' + window.encodeURIComponent(window.location.href.replace(/#.*/, '#/') + '?oauth'
      + (back_uri ? '&back_uri=' + back_uri : '')
    ));
}

const redirect = (href: any) => {
  window.location.href = href;
}

const clearUserData = () => {
  localStorage.setItem(accessTokenKey, '');
  localStorage.setItem('auth', '');
  localStorage.setItem('current_account_id', '');
  localStorage.setItem('user', '');
  localStorage.setItem('accounts', '');
  localStorage.setItem('current_business_id', '');
  setAuthHeader('');
}

export const logout = () => {
  const accessToken = localStorage.getItem(accessTokenKey);
  clearUserData();
  logoutAuth(accessToken || '');
};

const logoutAuth = (accessToken: string) => {
  redirect(
    `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/logout?`
    + 'client_id=' + (window as any).ApiConfig.client_id
    + '&client_secret=' + (window as any).ApiConfig.client_secret
    + '&response_type=token'
    + '&access_token=' + accessToken
    + '&redirect_uri=' + window.encodeURIComponent(window.location.href.replace(/#.*/, '#/') + '?oauth'
  ));
};
