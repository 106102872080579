import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import {
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Icon
} from '@material-ui/core';
import Loader from '../common/Loader';
import {
    messagesCenterTopicsGET,
    messagePOST
} from '../../services/ApiService';
import { ActiveRouteHeadingContext } from '../../Routing';
import { useHistory } from 'react-router-dom';
import AppData from '../../services/AppData';

const initialValues = {
    body: '',
    subject: '',
    topic_id: '',
    is_private: false
};

export default function NewConversation() {
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext);
    const classes = useStyles();
    const [values, setValues] = useState(initialValues);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    let history = useHistory();

    useEffect(
        () => {
            setActiveRouteHeading('New Conversation');

            messagesCenterTopicsGET()
                .then((res: any) => {
                    setTopics(res);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                });
        },
        [setActiveRouteHeading]
    );

    useEffect(
        () => {
            setSubmitDisabled(
                !(values.topic_id && values.subject && values.body)
            );
        },
        [values]
    );

    const handleChange = (event: any) => {
        const { target } = event;
        const { name, value, checked } = target;
        event.persist();
        setValues({
            ...values,
            [name]: name === 'is_private' ? checked : value
        });
    };

    const onSubmit = () => {
        const { is_private, ...body } = values;
        setLoading(true);
        messagePOST(
            is_private
                ? AppData.getPersonalAccount().id
                : AppData.getCurrentAccountId(),
            {
                ...body,
                accountants: (topics.find(
                    (t: any) => t.id === values.topic_id
                ) || {
                    assigned_accountant_ids: []
                }).assigned_accountant_ids
            }
        )
            .then((res: any) => {
                history.push(`/message_center/${res.id}`);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    return (
        <div className={classes.newConverSationMainCOntainer}>
            {loading
                ? <div className={classes.loader}>
                      <Loader />
                  </div>
                : <form className={classes.form} noValidate autoComplete="off">
                      <Grid
                          className={classes.formContainer}
                          container
                          direction="column"
                          justify="space-evenly"
                          alignItems="center"
                      >
                          <div className={classes.conversationContainer}>
                              <Grid item xs={12} sm={8} md={4}>
                                  <Grid container direction="column">
                                      <FormControl
                                          className={classes.formControl}
                                          variant="outlined"
                                      >
                                          <TextField
                                              required
                                              label="Subject"
                                              variant="outlined"
                                              name="subject"
                                              value={values.subject}
                                              onChange={handleChange}
                                          />
                                      </FormControl>
                                      <FormControl
                                          required
                                          className={classes.formControl}
                                      >
                                          <InputLabel>
                                              Select Team Member
                                          </InputLabel>
                                          <Select
                                              value={values.topic_id}
                                              name="topic_id"
                                              label="Age"
                                              onChange={handleChange}
                                          >
                                              {topics.map((topic: any) =>
                                                  <MenuItem
                                                      key={topic.id}
                                                      value={topic.id}
                                                  >
                                                      {topic.title}
                                                  </MenuItem>
                                              )}
                                          </Select>
                                      </FormControl>
                                      <FormControl
                                          className={classes.formControl}
                                      >
                                          <FormControlLabel
                                              control={
                                                  <Checkbox
                                                      name="is_private"
                                                      value={values.is_private}
                                                      color="default"
                                                      inputProps={{
                                                          'aria-label':
                                                              'checkbox with default color'
                                                      }}
                                                      onChange={handleChange}
                                                  />
                                              }
                                              label="Is Private"
                                          />
                                      </FormControl>
                                  </Grid>
                              </Grid>
                          </div>
                          <div className={classes.messageBodayContainer}>
                              <Grid item xs={12} sm={10} md={8}>
                                  <FormControl
                                      required
                                      className={
                                          classes.formControlMessageBoday
                                      }
                                  >
                                      <TextField
                                          name="body"
                                          placeholder="Type a message here..."
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          onChange={handleChange}
                                          value={values.body}
                                      />
                                  </FormControl>
                                  <Grid
                                      container
                                      alignItems="center"
                                      justify="flex-end"
                                  >
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          disableElevation
                                          classes={{ root: classes.sendButton }}
                                          component="div"
                                          endIcon={<Icon>send</Icon>}
                                          disabled={submitDisabled}
                                          onClick={onSubmit}
                                      >
                                          Send
                                      </Button>
                                  </Grid>
                              </Grid>
                          </div>
                      </Grid>
                  </form>}
        </div>
    );
}
