import React, { useContext, useEffect, useState, Fragment } from 'react';
import { ActiveRouteHeadingContext } from '../../Routing';
import {
    messagesGET,
    messagesCenterTopicsGET
} from '../../services/ApiService';
import Loader from '../common/Loader';
import { useStyles } from './styles';
import {
    Button,
    Grid,
    List,
    ListItem,
    Card,
    CardContent,
    Typography,
    Box
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import InfoDialog from '../common/InfoDialog';

export default function MessageCenter() {
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext);
    const [loading, setLoading] = useState(true);
    const [messageData, setmessageData] = useState<any>();
    const [topics, setTopics] = useState([]);
    const [open, setOpen] = React.useState(false);
    let history = useHistory();

    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(
        () => {
            setActiveRouteHeading('Message Center');

            Promise.all([
                messagesGET({ perPage: 100 }),
                messagesCenterTopicsGET()
            ])
                .then((res: any) => {
                    setmessageData(res[0]);
                    setTopics(res[1]);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                });
        },
        [setActiveRouteHeading]
    );
    return (
        <div className={classes.mainContainer}>
            <InfoDialog
                open={open}
                onClose={handleClose}
                text="We’re finding the perfect person to manage your account. You can start your conversation once we do. Check back soon."
            />
            {loading
                ? <div className={classes.loader}>
                      <Loader />
                  </div>
                : !messageData.items.length
                  ? <Grid
                        container
                        alignItems="center"
                        justify="center"
                        direction="column"
                    >
                        <Typography
                            variant="body1"
                            style={{ color: '#939dad', textAlign: 'center' }}
                            gutterBottom
                            component="div"
                        >
                            <p>
                                Have questions for your fulfillment
                                representative? Click below to start a
                                conversation.
                            </p>
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            classes={{
                                root: topics.length
                                    ? classes.startButton
                                    : classes.startButtonDisabled
                            }}
                            component={topics.length ? Link : 'a'}
                            to="/message_center/new"
                            onClick={handleClickOpen}
                        >
                            Start Conversation
                        </Button>
                    </Grid>
                  : <Grid
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        className={classes.listViewContainer}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justify="flex-end"
                            direction="row"
                            className={classes.newButtonWrapper}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                classes={{
                                    root: topics.length
                                        ? classes.startButton
                                        : classes.startButtonDisabled
                                }}
                                component={topics.length ? Link : 'a'}
                                to="/message_center/new"
                                onClick={handleClickOpen}
                            >
                                New Conversation
                            </Button>
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                        >
                            <List style={{ width: '100%' }}>
                                {messageData.items.map(
                                    (item: any, index: number) =>
                                        <Fragment key={index}>
                                            <ListItem>
                                                <Card
                                                    onClick={() =>
                                                        history.push(
                                                            `/message_center/${item.id}`
                                                        )}
                                                    className={
                                                        classes.messageCard
                                                    }
                                                >
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="space-between"
                                                            alignItems="flex-start"
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                display="block"
                                                                component="div"
                                                                gutterBottom
                                                                style={{
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                <Box
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                    className={
                                                                        classes.listMessageSubject
                                                                    }
                                                                >
                                                                    {
                                                                        item.subject
                                                                    }
                                                                </Box>
                                                            </Typography>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                className={
                                                                    classes.listMessageTime
                                                                }
                                                            >
                                                                {moment(
                                                                    item
                                                                        .last_message
                                                                        .date *
                                                                        1000
                                                                ).format(
                                                                    'DD MMM YYYY, h:mm A'
                                                                )}
                                                            </Typography>
                                                        </Grid>

                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            gutterBottom
                                                            component="div"
                                                        >
                                                            <Box
                                                                className={
                                                                    classes.listMessageBody
                                                                }
                                                            >
                                                                {item
                                                                    .last_message
                                                                    .portal_user
                                                                    ? `${item
                                                                          .last_message
                                                                          .portal_user
                                                                          .first_name} ${item
                                                                          .last_message
                                                                          .portal_user
                                                                          .last_name}`
                                                                    : `${item
                                                                          .last_message
                                                                          .accountant
                                                                          .first_name} ${item.last_message.accountant.last_name.substr(
                                                                          0,
                                                                          1
                                                                      )}`}
                                                                {': '}
                                                                {
                                                                    item
                                                                        .last_message
                                                                        .body
                                                                }
                                                            </Box>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </ListItem>
                                        </Fragment>
                                )}
                            </List>
                        </Grid>
                    </Grid>}
        </div>
    );
}
