import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core';
import { useAuth } from '../../services/AuthService';
import Dashboard from '../dashboard';
import Documents from '../documents';
import MessageCenter from '../message_center';
import NotFoundView from './NotFoundView';
import NewConversation from '../message_center/NewConversation';
import ConversationDetail from '../message_center/ConversationDetail';
import BusinessDetails from '../business-details/BusinessDetails';

export default function AppView() {
    const classes = makeStyles((theme: Theme) => {
        return {
            content: {
                height: 'calc(100% - 72px)',
                marginTop: 72,
                [theme.breakpoints.down('sm')]: {
                    height: 'calc(100% - 56px)',
                    marginTop: 56,
                    backgroundColor: theme.palette.background.default,
                    paddingLeft: 0,
                    paddingRight: 0,
                }
            }
        }
    })();
    const auth = useAuth();
    const location = useLocation();

    if (!auth.isLoggedIn()) {
        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
    }

    return (
        <Container fixed className={classes.content}>
            <Switch>
                <Route path="/dashboard" exact>
                    <Dashboard />
                </Route>
                <Route path="/documents/:fileId?" exact>
                    <Documents />
                </Route>
                <Route path="/message_center" exact>
                    <MessageCenter />
                </Route>
                <Route path="/message_center/new" exact>
                    <NewConversation />
                </Route>
                <Route path="/message_center/:id">
                    <ConversationDetail />
                </Route>    
                <Route path="/business-details" exact>
                    <BusinessDetails />
                </Route>
                <Route path="*">
                    <NotFoundView />
                </Route>
            </Switch>
        </Container>
    )
}
