import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    useCallback
} from 'react';
import {
    Grid,
    FormControl,
    TextField,
    Button,
    Icon,
    List,
    ListItem,
    Typography,
    Box,
    Divider
} from '@material-ui/core';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { ActiveRouteHeadingContext } from '../../Routing';
import { messageGET, replyPOST } from '../../services/ApiService';
import Loader from '../common/Loader';
import moment from 'moment';

export default function ConversationDetail() {
    let { id } = useParams();
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext);
    const [loading, setLoading] = useState(true);
    const [conversation, setConversation] = useState<any>({});
    const [body, setBody] = useState<string>('');

    const loadConversation = useCallback(
        () => {
            messageGET(id)
                .then((res: any) => {
                    setConversation(res);
                    setBody('');
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                });
        },
        [id]
    );

    useEffect(
        () => {
            setActiveRouteHeading('Conversation');
            loadConversation();
        },
        [setActiveRouteHeading, loadConversation]
    );

    const classes = useStyles();

    const handleChange = ({ target }: any) => {
        const { value } = target;
        setBody(value);
    };

    const onSubmit = () => {
        setLoading(true);
        replyPOST(id, { body }).then(loadConversation).catch(err => {
            setLoading(false);
        });
    };

    return (
        <div className={classes.newConverSationMainCOntainer}>
            {loading
                ? <div className={classes.loader}>
                      <Loader />
                  </div>
                : <div className={classes.conversationDetailContainer}>
                      <Grid item xs={12} sm={10} md={8}>
                          <Typography variant="h6" gutterBottom>
                              {conversation.subject}
                          </Typography>
                          <div>
                              <List>
                                  {conversation.messages.map(
                                      (message: any, index: number) =>
                                          <Fragment key={index}>
                                              <ListItem>
                                                  <Grid
                                                      container
                                                      direction="column"
                                                      justify="center"
                                                      alignItems="stretch"
                                                  >
                                                      <Grid
                                                          container
                                                          direction="row"
                                                          justify="space-between"
                                                          alignItems="flex-start"
                                                      >
                                                          <Typography
                                                              color="primary"
                                                              variant="body2"
                                                              gutterBottom
                                                          >
                                                              <Box
                                                                  component="span"
                                                                  fontWeight={
                                                                      700
                                                                  }
                                                              >
                                                                  {message.portal_user
                                                                      ? message
                                                                            .portal_user
                                                                            .first_name +
                                                                        ' ' +
                                                                        message
                                                                            .portal_user
                                                                            .last_name
                                                                      : message
                                                                            .accountant
                                                                            .first_name +
                                                                        ' ' +
                                                                        message.accountant.last_name.substr(
                                                                            0,
                                                                            1
                                                                        ) +
                                                                        ' (Fulfillment Representative)'}
                                                              </Box>
                                                          </Typography>
                                                          <Typography
                                                              variant="caption"
                                                              display="block"
                                                              gutterBottom
                                                          >
                                                              <Box fontWeight="fontWeightLight">
                                                                  {moment(
                                                                      message.date *
                                                                          1000
                                                                  ).format(
                                                                      'DD MMM YYYY, h:mm A'
                                                                  )}
                                                              </Box>
                                                          </Typography>
                                                      </Grid>

                                                      <Typography
                                                          variant="body2"
                                                          gutterBottom
                                                      >
                                                          {message.body}
                                                      </Typography>
                                                  </Grid>
                                              </ListItem>
                                              {index !==
                                              conversation.messages.length - 1
                                                  ? <Divider />
                                                  : ''}
                                          </Fragment>
                                  )}
                              </List>
                          </div>
                          <div>
                              <FormControl
                                  required
                                  className={classes.formControlMessageBoday}
                              >
                                  <TextField
                                      name="body"
                                      placeholder="Type message here..."
                                      multiline
                                      rows={4}
                                      variant="outlined"
                                      value={body}
                                      onChange={handleChange}
                                  />
                              </FormControl>
                              <Grid
                                  container
                                  alignItems="center"
                                  justify="flex-end"
                              >
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                      classes={{ root: classes.sendButton }}
                                      component="div"
                                      endIcon={<Icon>send</Icon>}
                                      disabled={!body}
                                      onClick={onSubmit}
                                  >
                                      Send
                                  </Button>
                              </Grid>
                          </div>
                      </Grid>
                  </div>}
        </div>
    );
}
