import React, { Fragment, useContext, useEffect } from 'react'
import { Hidden, Grid } from '@material-ui/core'
import RecentlyUploadedDocumentsCard from './RecentlyUploadedDocumentsCard'
import RegistrationProgressCard from './RegistrationProgressCard'
import { ActiveRouteHeadingContext } from '../../Routing'

export default function Dashboard() {
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext)
    useEffect(() => {
        setActiveRouteHeading('Dashboard')
    }, [setActiveRouteHeading])
    return (
        <Fragment>
            <Hidden smDown>
                <Grid container spacing={2} justify="space-between">
                    <Grid item md={7}>
                        <RegistrationProgressCard />
                        <RecentlyUploadedDocumentsCard />
                    </Grid>
                    <Grid item md={5}>
                        {/* <SetupAgentCard />
                        <TaxHelpCard /> */}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={12}>
                        <RegistrationProgressCard />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <SetupAgentCard />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <RecentlyUploadedDocumentsCard />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <TaxHelpCard />
                    </Grid>*/}
                </Grid>
            </Hidden>
        </Fragment>
    )
}
