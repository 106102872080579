import documentsIcon from '../../assets/icons-svg/Documents.svg';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return {
        mainContainer: {
            backgroundColor: '#f5f5f9',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center',
            width: 'calc(100% + 72px)',
            marginLeft: -36,
            minHeight: '100%',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                width: '100%'
            }
        },
        loader: {
            alignSelf: 'center'
        },
        startButton: {
            color: '#fff',
            alignSelf: 'center'
        },
        startButtonDisabled: {
            alignSelf: 'center',
            color: 'rgba(0, 0, 0, 0.26) !important',
            boxShadow: 'none !important',
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            cursor: 'not-allowed !important',
            "&:hover": {
                color: '#fff !important',
                backgroundColor: '#20c668 !important',
                borderColor: '#1cb05c !important'
            }
        },
        listViewContainer: {
            padding: '20px 40px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 0 20px 0',
            }
        },
        newButtonWrapper: {
            paddingRight: '16px'
        },
        messageCard: {
            width: '100%',
            height: '80px',
            cursor: 'pointer'
        },
        listMessageBody: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            lineHeight: '25px',
            color: '#8b95a5',
            fontWeight: 400
        },
        listMessageSubject: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        listMessageTime: {
            color: '#8b95a5',
            fontWeight: 400
        },
        newConverSationMainCOntainer: {
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center',
            width: 'calc(100% + 72px)',
            marginLeft: -36,
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                width: '100%'
            }
        },
        form: {
            width: '100%'
        },
        formContainer: {
            height: '100%',
            width: '100%',
            padding: '20px 40px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 20px 20px 20px',
            }
        },
        formControl: {
            margin: '12px !important'
        },
        conversationContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        messageBodayContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        formControlMessageBoday: {
            width: '100%'
        },
        messageBody: {
            width: 710
        },
        sendButton: {
            color: '#fff',
            marginTop: 20
        },
        conversationDetailContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 40px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 20px 20px 20px',
            }
        },

        filesContainer: {
            backgroundColor: '#F5F5F9',
            padding: '20px 20px 60px 46px',
            flex: '1 1 40%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                padding: 15
            }
        },
        uploadButtonContainer: {
            textAlign: 'right'
        },

        uploadInput: {
            display: 'none'
        },
        previewContainer: {
            paddingLeft: 17,
            paddingRight: 17,
            flex: '1 1 60%',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        preview: {
            background: `rgba(255, 255,255, 0.9) url(${documentsIcon}) center/150px no-repeat`,
            backgroundBlendMode: 'lighten',
            height: '100%'
        },
        previewWithoutIcon: {
            backgroundBlendMode: 'lighten',
            height: '100%'
        },
        noDocs: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontSize: 14
        },
        centerData: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        progressColorDark: {
            color: '#01C168',
            strokeLinecap: 'round'
        },
        listIcon: {
            minWidth: 36
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 10,
            padding: '15px 0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: 15
            }
        },
        metaData: {
            fontSize: 14
        },
        fileName: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 600
        },
        image: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
            backgroundColor: '#f5f5f9'
        },
        dialogTitle: {
            padding: 10
        },
        dialogContent: {
            paddingTop: 0
        }
    };
});
