import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => {
    return {
        detailsContainer: {
            [theme.breakpoints.down('sm')]: {
                order: 1,
            }
        },
        businessName: {
            color: '#8b95a5',
            marginBottom: 20,
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        field: {
            fontSize: 12.5,
            paddingTop: '5px !important',
            paddingBottom: '0px !important'
        },
        fieldsContainer: {
            marginBottom: 0
        },
        customPaddingItem: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: 10
            }
        },
        businessOwnerfield: {
            fontSize: 12.5,
            padding: '5px 10px 10px 10px'
        },
        value: {
            fontWeight: 'bold',
            marginTop: 5,
            textTransform: 'capitalize',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0
            }
        },
        businessOwner: {
            border: '10px solid #dfe7ea',
            paddingTop: 10,
            marginBottom: 10,
            marginTop: 10
        },
        businessOwnerAddressDivider: {
            [theme.breakpoints.down('sm')]: {
                margin: '10px 0'
            }
        },
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: 15
            }
        },
        logoContainer: {
            paddingLeft: 12,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingLeft: 0
            }
        },
        logo: {
            padding: 5,
            border: '1px solid #e0e0e0',
            minWidth: 250,
            [theme.breakpoints.down('sm')]: {
                margin: '15px 0'
            }
        },
        horizontalDivider: {
            [theme.breakpoints.down('sm')]: {
                margin: '10px 0',
                width: '100%'
            }
        },
        customDivider: {
            [theme.breakpoints.down('sm')]: {
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                marginLeft: -11,
                paddingLeft: 10
            }
        }
    }
})