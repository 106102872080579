import {
    makeStyles,
    Dialog,
    withStyles,
    Typography,
    IconButton,
    WithStyles,
    DialogContent,
    DialogActions,
    Button,
    Theme,
    createStyles
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    content: {
        backgroundColor: '#f5f5f9',
        textAlign: 'center',
        color: '#2d323b'
    },
    actionWrapper: {
        justifyContent: 'center'
    },
    actionButton: {
        color: '#fff'
    }
});

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            justifyContent: 'center',
            alignUtems: 'center',
            display: 'flex',
            backgroundColor: '#f5f5f9'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose
                ? <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={onClose}
                  >
                      <CloseIcon />
                  </IconButton>
                : null}
        </MuiDialogTitle>
    );
});

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    text: string;
}

export default function InfoDialog(props: SimpleDialogProps) {
    const classes = useStyles();
    const { onClose, open, text } = props;

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                <ErrorOutlineIcon
                    color="disabled"
                    style={{ fontSize: 100, fontWeight: 100 }}
                />
            </DialogTitle>

            <DialogContent className={classes.content}>
                <Typography gutterBottom>
                    {text}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actionWrapper}>
                <Button
                    className={classes.actionButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={onClose}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
