import axios, { CancelToken } from 'axios';
import { logout } from './AuthService';


const instance = axios.create({
  baseURL: `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/`
});

export const setAuthHeader = (token: any) => {
  instance.defaults.headers.common['Authorization'] = token;
}

setAuthHeader(`Bearer ${localStorage.getItem('access_token')}`);

export const getResource = (subUrl: any, cancelToken?: CancelToken, params?:any) => {
  return requestHandler('get', subUrl, undefined, cancelToken, params);
};

export const postResource = (subUrl: any, body: any, cancelToken?: CancelToken) => {
  return requestHandler('post', subUrl, body, cancelToken);
};

export const putResource = (subUrl: any, body: any, cancelToken?: CancelToken) => {
  return requestHandler('put', subUrl, body, cancelToken);
};

export const deleteResource = (subUrl: any, cancelToken?: CancelToken) => {
  return requestHandler('delete', subUrl, undefined, cancelToken);
};

const requestHandler = (method: any, subUrl: any, body: any, cancelToken?: CancelToken, params?:any) => {
  return new Promise((resolve, reject) => {
    instance
      .request({
        method: method,
        url: subUrl,
        data: body,
        cancelToken,
        params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if(error.response && error.response.status === 401) {
          logout();
        }
        else if (error.response) {
          reject({
            status: error.response.status,
            statusText: error.response.data.message || error.response.data.error
          });
        } else {
          reject();
        } 
      });
  });
};