import React, { useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from '../../styles';
import { makeStyles, Theme, CircularProgress } from '@material-ui/core';
import { uploadedFilesGET } from '../../services/ApiService';
import { Link } from 'react-router-dom';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

export default function RecentlyUploadedDocumentsCard() {
    const classes = makeStyles((theme: Theme) => {
        return {
            documentsListItemIconRoot: {
                width: '17px',
                height: '17px',
                borderRadius: 3.5,
                color: '#FFF',
                backgroundColor: '#F34747',
                padding: 2,
                minWidth: 17,
                fontSize: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bolder',
                margin: '10px 8px 0 0',
                alignSelf: 'flex-start'
            },
            cardContent: {
                paddingLeft: 8,
                paddingRight: 8,
                [theme.breakpoints.down('sm')]: {
                    paddingTop: 0,
                    paddingBottom: '0 !important'
                }
            },
            listItem: {
                paddingLeft: 8,
                paddingRight: 8
            },
            mainDivider: {
                marginBottom: 8
            },
            loaderContainer: {
                width: '100%',
                textAlign: 'center'
            },
            error: {
                color: '#bbb'
            },
            progressColorDark: {
                color: '#01C168',
                strokeLinecap: 'round',
                position: 'absolute'
            }
        };
    })();

    const rootClasses = useStyles();
    const [loadingDocs, setLoadingDocs] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);

    useEffect(() => {
        const CancelToken = Axios.CancelToken;
        let source = CancelToken.source();
        setCancelTokens(prev => prev.concat(source));

        uploadedFilesGET(source.token)
            .then((res: any) => {
                setDocuments(res.items);
                setLoadingDocs(false);
            })
            .catch(err => {
                if (Axios.isCancel(err) || !err) {
                    return;
                }
                setErrorMsg('Error while loading documents.');
                setLoadingDocs(false);
            });
    }, []);

    useEffect(() => () => cancelTokens.forEach(token => token.cancel()), [
        cancelTokens
    ]);

    return (
        <Card elevation={0} classes={{ root: rootClasses.cardRoot }}>
            <CardHeader
                classes={{
                    root: rootClasses.cardHeaderRoot,
                    title: rootClasses.cardHeaderTitle
                }}
                title="Recently Uploaded"
                titleTypographyProps={{ variant: 'h6' }}
                action={
                    <Button
                        component={Link}
                        to="/documents"
                        color="primary"
                        classes={{ root: rootClasses.cardHeaderButton }}
                    >
                        Go to Documents
                    </Button>
                }
            />
            <Divider
                classes={{ root: rootClasses.cardDivider }}
                className={classes.mainDivider}
            />
            <CardContent
                classes={{ root: rootClasses.cardContentRoot }}
                className={classes.cardContent}
            >
                {loadingDocs
                    ? <div className={classes.loaderContainer}>
                          <CircularProgress
                              variant="indeterminate"
                              className={classes.progressColorDark}
                          />
                      </div>
                    : errorMsg
                      ? <div
                            className={`${classes.loaderContainer} ${classes.error}`}
                        >
                            {errorMsg}
                        </div>
                      : <List disablePadding>
                            {documents.map((doc: any, index) =>
                                <Fragment key={index}>
                                    <ListItem
                                        disableGutters
                                        button
                                        classes={{ root: classes.listItem }}
                                        component={Link}
                                        to={{
                                            pathname: `/documents/${doc.id}`
                                        }}
                                    >
                                        <ListItemIcon
                                            classes={{
                                                root:
                                                    classes.documentsListItemIconRoot
                                            }}
                                        >
                                            PDF
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={doc.filename}
                                            secondary={`Uploaded ${moment(
                                                doc.upload_date
                                            ).format('DD MMM YYYY | h:mm A')}`}
                                        />
                                    </ListItem>
                                    {index + 1 !== documents.length &&
                                        <Divider />}
                                </Fragment>
                            )}
                        </List>}
            </CardContent>
        </Card>
    );
}
