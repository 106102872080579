const data = {
  list: [],
  user: '',
  current_account_id: '',
  current_business_id: ''
};

export const initData = () => {
  data.list = JSON.parse(localStorage.getItem('accounts') || '{}');
  data.user = JSON.parse(localStorage.getItem('user') || '{}');
  data.current_account_id  = localStorage.getItem('current_account_id') || '';
  data.current_business_id = localStorage.getItem('current_business_id') || '';
};

export const setUserData = (user: any) => {
  localStorage.setItem('user', user);
};

export const setAccountsData = (accounts: []) => {
  let account_list: any = [];
  accounts.forEach((acc_data: any) => {
    let account = acc_data.account;
    account.business = acc_data.business;
    account.access_rights = acc_data.access_rights;
    account.has_archived_business = acc_data.has_archived_business;
    account.mc_only = acc_data.mc_only || false;
    account_list.push(account);
  });
  localStorage.setItem('accounts', JSON.stringify(account_list));
  data.list = account_list;

  localStorage.setItem('current_account_id', account_list[1].id);
  localStorage.setItem('current_business_id', account_list[1].business.id);
};

export const user = () => data.user;

export const accounts = () => data.list;

export const personalAccount = () => data.list.find((acc: any) => acc.account_type === 'personal');

export const currentAccount = () => data.list.find((acc: any) => acc.id === data.current_account_id);

export const currentAccountId = () => data.current_account_id;

export const currentBusiness = () => (currentAccount() || { business: {}})['business'];

export const currentBusinessId = () => data.current_business_id;


