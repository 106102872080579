import React from 'react';
import { AuthProvider } from './services/AuthService';
import Routing from './Routing';

function App() {
  return (
    <AuthProvider>
      <Routing />
    </AuthProvider>
  );
}

export default App;
