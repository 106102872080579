// import Axios from "axios";
import { getResource, postResource, deleteResource } from './AxiosService';
import AppData from './AppData';
import { CancelToken } from 'axios';

export function entityFormationProgressGET(accountId: string, cancelToken: CancelToken) {
  return getResource(`api/account/${accountId}/entity_formation/progress`, cancelToken);
}

export function portalDetailsGET() {
  return getResource('api/user/current/portal');
}

// DOCUMENTS
export function uploadedFilesGET(cancelToken?: CancelToken) {
  return getResource(
    `api/account/${AppData.getCurrentAccountId()}/file_center/file?page=1&path=/permanent_files/business_formation_documents&perPage=10&type=business`,
    cancelToken
  );
}

export function uploadFilePOST(body: any, cancelToken: CancelToken) {
  return postResource(
    `api/account/${AppData.getCurrentAccountId()}/file_center/upload`,
    body,
    cancelToken
  );
}

export function deleteFileDELETE(fileId: string, cancelToken: CancelToken) {
  return deleteResource(
    `api/account/${AppData.getCurrentAccountId()}/file_center/file/${fileId}`,
    cancelToken
  );
}


//MESSAGE CENTER

export function messagesGET(params: any) {
  return getResource(
    `api/message-center/account/${AppData.getCurrentAccountId()}/conversation`,
    undefined,
    params
  );
}

export function messagesCenterTopicsGET() {
  return getResource(
    'api/message-center/topic'
  );
}

export function messagePOST(accountId: string, body: any) {
  return postResource(
    `api/message-center/account/${accountId}/conversation`,
    body
  )
}

export function messageGET(conerSationId: string) {
  return getResource(
    `api/message-center/account/${AppData.getCurrentAccountId()}/conversation/${conerSationId}`,
    undefined,
  );
}

export function replyPOST(conersationId: string, body: any) {
  return postResource(
    `api/message-center/account/${AppData.getCurrentAccountId()}/conversation/${conersationId}/messages`,
    body,
    undefined,
  );
}


// CONFIG
export function getApiConfig() {
  return getResource('config');
}
