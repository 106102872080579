import documentsIcon from '../../assets/icons-svg/Documents.svg'
import { makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => {
    return {
        mainContainer: {
            display: 'flex',
            alignItems: 'stretch',
            width: 'calc(100% + 72px)',
            marginLeft: -36,
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                width: '100%'
            }
        },
        filesContainer: {
            backgroundColor: '#F5F5F9',
            padding: '20px 20px 60px 46px',
            flex: '1 1 40%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                padding: 15
            }
        },
        uploadButtonContainer: {
            textAlign: 'right'
        },
        uploadButton: {
            color: '#fff'
        },
        uploadInput: {
            display: 'none'
        },
        previewContainer: {
            paddingLeft: 17,
            paddingRight: 17,
            flex: '1 1 60%',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        preview: {
            background: `rgba(255, 255,255, 0.9) url(${documentsIcon}) center/150px no-repeat`,
            backgroundBlendMode: 'lighten',
            height: '100%'
        },
        previewWithoutIcon: {
            backgroundBlendMode: 'lighten',
            height: '100%'
        },
        noDocs: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontSize: 14
        },
        centerData: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        progressColorDark: {
            color: '#01C168',
            strokeLinecap: 'round'
        },
        listIcon: {
            minWidth: 36
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 10,
            padding: '15px 0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: 15
            }
        },
        metaData: {
            fontSize: 14
        },
        fileName: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 600
        },
        image: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
            backgroundColor: '#f5f5f9',
            border: '1px solid #f5f5f9'
        },
        dialogTitle: {
            padding: 10
        },
        dialogContent: {
            paddingTop: 0
        }
    }
})