import React, { Fragment } from 'react'
import { Hidden, Dialog, Slide, DialogTitle, DialogContent, Typography, DialogActions, IconButton, Button } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useStyles } from './styles';
import { Trash, ArrowLeft } from 'react-feather';
import imageIcon from '../../assets/icons-svg/Image.svg'
import AppData from '../../services/AppData';
import moment from "moment";

export default function FilePreview({
    modalOpen,
    handleClearFile,
    currentFile,
    handleConfirmDelete
}: {
    modalOpen: boolean,
    handleClearFile: any,
    currentFile: any,
    handleConfirmDelete: any
}) {

    const classes = useStyles()

    const fileHeading = (
        <div className={classes.fileName}>
            <div>
                {currentFile.filename}
            </div>
            <IconButton onClick={() => handleConfirmDelete(true)}>
                <Trash fontSize="small" />
            </IconButton>
        </div>
    )

    const dialogHeading = (
        <div className={classes.fileName}>
            <IconButton onClick={handleClearFile}>
                <ArrowLeft fontSize="small" />
            </IconButton>
            <div style={{
                flex: 1
            }}>
                File Preview
            </div>
            <IconButton onClick={() => handleConfirmDelete(true)}>
                <Trash fontSize="small" />
            </IconButton>
        </div>
    )

    const fileImage = (
        <div className={classes.image}>
            {
                <img
                    style={{ minWidth: 100, maxWidth: '100%' }}
                    src={
                        currentFile.is_preview_available
                            ? `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/api/account/${AppData.getCurrentAccountId()}/file_center/file/${currentFile.id}/view`
                            : imageIcon
                    }
                    alt="Preview"
                />
            }
        </div>
    )

    const fileFooter = (
        <div className={classes.footer}>
            <div className={classes.metaData}>{moment(currentFile.upload_date).format('DD MMM YYYY')}, {Math.round(currentFile.size / 1024)} Kb</div>
            <Button
                variant="contained"
                color="primary"
                disableElevation
                classes={{ root: classes.uploadButton }}
                component="a"
                target="_blank"
                href={`${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/api/account/${AppData.getCurrentAccountId()}/file_center/file/${currentFile.id}/download`}
            >
                Download
            </Button>
        </div>
    )

    const fileDisplay = (
        <div>
            {fileHeading}
            {fileImage}
            {fileFooter}
        </div>
    )

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    });
    return (
        <Fragment>
            <Hidden smDown>
                {modalOpen && fileDisplay}
            </Hidden>
            <Hidden mdUp>
                {
                    modalOpen && (
                        <Dialog
                            fullScreen
                            open={modalOpen}
                            TransitionComponent={Transition}
                            onClose={handleClearFile}
                        >
                            <DialogTitle
                                classes={{ root: classes.dialogTitle }}
                                id="select-file-dialog-title"
                            >{dialogHeading}</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogContent }}>
                                <Typography variant="h6" gutterBottom>
                                    {currentFile.filename}
                                </Typography>
                                {fileImage}
                            </DialogContent>
                            <DialogActions>
                                {fileFooter}
                            </DialogActions>
                        </Dialog>
                    )
                }
            </Hidden>
        </Fragment>
    )
}
