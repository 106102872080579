import React, { useEffect, useState, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';

import { useStyles } from '../../styles';
import DashboardProgressCircle from '../ui/DashboardProgressCircle';
import { makeStyles, Theme, CircularProgress } from '@material-ui/core';
import RegistrationListItems from './RegistrationListItems';
import { entityFormationProgressGET } from '../../services/ApiService';
import BusinessDetails from './BusinessDetails';
import AppData from '../../services/AppData';
import Axios, { CancelTokenSource } from 'axios';

export default function RegistrationProgressCard() {
    const rootClasses = useStyles();
    const classes = makeStyles((theme: Theme) => {
        return {
            circleProgress: {
                display: 'flex',
                marginBottom: '1rem',
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5)
            },
            stepListItemIconRoot: {
                minWidth: '27px'
            },
            progressColorDark: {
                color: '#01C168',
                strokeLinecap: 'round',
                position: 'absolute'
            },
            loaderContainer: {
                width: '100%',
                textAlign: 'center'
            },
            error: {
                color: '#bbb'
            }
        };
    })();
    const [apiResponse, setApiResponse] = useState<{
        current_step: string;
        completed_steps: string[];
        remaining_steps: string[];
        percentage_completed: number;
    }>({
        current_step: '',
        completed_steps: [],
        remaining_steps: [],
        percentage_completed: 0
    });
    const [formationCompletedAt, setFormationCompletedAt] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const CancelToken = Axios.CancelToken;
        let source = CancelToken.source();
        setCancelTokens(prev => prev.concat(source));

        entityFormationProgressGET(
            AppData.getPersonalAccount().id,
            source.token
        )
            .then((response: any) => {
                setApiResponse(response);
                if (response.current_step === 'completed') {
                    setFormationCompletedAt(response.completed_at * 1000);
                }
                setLoading(false);
            })
            .catch(err => {
                if (err.response) {
                    const data = err.response.data;
                    if (data.code === 400) {
                        setError(data.message);
                    } else if (data.code === 500) {
                        setError('Not able to load data at the moment.');
                    } else {
                        setError(data.error);
                    }
                } else if (Axios.isCancel(err) || !err) {
                    return;
                } else {
                    setError('Not able to load data at the moment.');
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => () => cancelTokens.forEach(token => token.cancel()), [
        cancelTokens
    ]);

    return (
        <Card elevation={0} classes={{ root: rootClasses.cardRoot }}>
            <CardHeader
                classes={{
                    root: rootClasses.cardHeaderRoot,
                    title: rootClasses.cardHeaderTitle
                }}
                title={
                    loading
                        ? ''
                        : apiResponse.current_step === 'completed'
                          ? 'Your Business'
                          : 'Registration Process'
                }
                titleTypographyProps={{ variant: 'h6' }}
            />
            <Divider className={rootClasses.cardDivider} />

            <CardContent classes={{ root: rootClasses.cardContentRoot }}>
                {loading
                    ? <div className={classes.loaderContainer}>
                          <CircularProgress
                              variant="indeterminate"
                              className={classes.progressColorDark}
                          />
                      </div>
                    : <Fragment>
                          {error
                              ? <div
                                    className={`${classes.loaderContainer} ${classes.error}`}
                                >
                                    {error}
                                </div>
                              : apiResponse.current_step === 'completed'
                                ? <BusinessDetails
                                      completedDate={formationCompletedAt}
                                  />
                                : <Fragment>
                                      <div className={classes.circleProgress}>
                                          <DashboardProgressCircle
                                              progress={Math.floor(
                                                  apiResponse.percentage_completed
                                              )}
                                          />
                                      </div>
                                      <RegistrationListItems
                                          completedItems={
                                              apiResponse.completed_steps
                                          }
                                          remainingItems={
                                              apiResponse.remaining_steps
                                          }
                                          currentStep={apiResponse.current_step}
                                      />
                                  </Fragment>}
                      </Fragment>}
            </CardContent>
        </Card>
    );
}
