import React, { Fragment, useState } from 'react'
import { Typography, List, ListItemIcon, ListItem, ListItemText, Divider, makeStyles, Theme } from '@material-ui/core'
import { Circle, CheckCircle, ChevronUp, ChevronDown } from 'react-feather'
import AppData from '../../services/AppData';

export default function RegistrationListItems({ completedItems, remainingItems, currentStep }: { completedItems: string[], remainingItems: string[], currentStep: string }) {
    const [areStepsExpanded, setAreStepsExpanded] = useState(false);
    const classes = makeStyles((theme: Theme) => {
        return {
            stepListItemIconRoot: {
                minWidth: '27px',
            },
            stepCompletedListItemIconRoot: {
                minWidth: '27px',
                color: '#20C668',
            },
            stepsHeader: {
                color: '#818A91',
            },
            currentStepHeader: {
                color: '#01C168',
                paddingLeft: '27px',
            },
            stepToggleExpandIcon: {
                minWidth: '27px',
                color: '#497EE7',
            },
            stepToggleExpandButtonText: {
                color: '#497EE7',
                fontWeight: 'bold',
            },
        }
    })();
    return (
        <React.Fragment>
            <Typography classes={{ root: classes.currentStepHeader }} variant="subtitle2">
                Current Step
            </Typography>

            <List disablePadding>
                <ListItem disableGutters>
                    <ListItemIcon classes={{ root: classes.stepListItemIconRoot }}><Circle size={19} /></ListItemIcon>
                    <ListItemText primary={AppData.config.entity_formation.statuses[currentStep]} />
                </ListItem>

                <Divider />

                <ListItem disableGutters button onClick={() => { setAreStepsExpanded(!areStepsExpanded) }}>
                    <ListItemIcon classes={{ root: classes.stepToggleExpandIcon }}>{areStepsExpanded ? <ChevronUp size={19} /> : <ChevronDown size={19} />}</ListItemIcon>
                    <ListItemText primary={areStepsExpanded ? 'Collapse' : 'See More'} classes={{ primary: classes.stepToggleExpandButtonText }} />
                </ListItem>
            </List>
            {
                areStepsExpanded && remainingItems.length > 0 && (
                    <Fragment>
                        <Typography variant="subtitle2" className={classes.stepsHeader}>{remainingItems.length} Remaining</Typography>
                        <List disablePadding>
                            {
                                remainingItems.map((step, index) =>
                                    <Fragment key={index}>
                                        <ListItem disableGutters>
                                            <ListItemIcon classes={{ root: classes.stepListItemIconRoot }}><Circle size={19} /></ListItemIcon>
                                            <ListItemText primary={AppData.config.entity_formation.statuses[step]} />
                                        </ListItem>

                                        <Divider />
                                    </Fragment>
                                )
                            }
                        </List>
                    </Fragment>
                )
            }
            {
                areStepsExpanded && completedItems.length > 0 && (
                    <Fragment>
                        <Typography variant="subtitle2" className={classes.stepsHeader}>{completedItems.length} Completed</Typography>
                        <List disablePadding>
                            {
                                completedItems.map((step, index) =>
                                    <Fragment key={index}>
                                        <ListItem disableGutters>
                                            <ListItemIcon classes={{ root: classes.stepCompletedListItemIconRoot }}><CheckCircle size={19} /></ListItemIcon>
                                            <ListItemText primary={AppData.config.entity_formation.statuses[step]} />
                                        </ListItem>

                                        {
                                            index !== completedItems.length - 1
                                            && <Divider />
                                        }
                                    </Fragment>
                                )
                            }
                        </List>
                    </Fragment>
                )
            }
        </React.Fragment>
    )
}
