import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, makeStyles, Theme } from '@material-ui/core'
import { AlertCircle, HelpCircle } from 'react-feather'

export default function CommonDialog({
    type,
    title,
    open,
    align,
    body,
    confirmAction,
    cancelAction
}: {
    type: 'alert' | 'confirm'
    title: string,
    open: boolean,
    body: string,
    align?: "left" | "right" | "inherit" | "center" | "justify" | undefined,
    confirmAction?: any,
    cancelAction: any
}) {
    const classes = makeStyles((theme: Theme) => {
        return {
            dialog: { minWidth: 300 },
            iconContainer: { textAlign: 'center', marginBottom: 15 }
        }
    })()
    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div className={classes.iconContainer}>
                    {
                        type === 'alert'
                            ? <AlertCircle size={72} strokeWidth={1.5} color="#bdbdbd" />
                            : <HelpCircle size={72} strokeWidth={1.5} color="#bdbdbd" />
                    }
                </div>
                <Typography variant="inherit" align={align || 'center'}>{body}</Typography>
            </DialogContent>
            <DialogActions>
                {
                    type === 'confirm' &&
                    <Button disableElevation onClick={confirmAction} color="primary">
                        Confirm
                    </Button>
                }
                <Button disableElevation onClick={() => cancelAction(false)}>
                    {type === 'confirm' ? 'Cancel' : 'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
