import { makeStyles, Theme } from '@material-ui/core/styles';
import BgImage from './assets/bg-images/tax_filling.png';
export const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => {
    return {
        rootDiv: {
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'absolute',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.background.default
            }
        },
        loaderPageDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover',
            background: `linear-gradient( rgba(0,0,0,0.2), rgba(0, 0, 0, 0.2) ),url(${BgImage}) no-repeat center center fixed`,
        },
        loader: {
            color: '#01C168 !important',
        },
        cardRoot: {
            border: 'none',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.background.default
            }
        },
        cardHeaderRoot: {
            paddingLeft: '24px !important',
            paddingRight: '24px !important',
            paddingTop: '24px !important',
            paddingBottom: '16px !important',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '16px !important',
                paddingRight: '16px !important',
                paddingTop: '16px !important',
                paddingBottom: '8px !important',
            }
        },
        cardHeaderTitle: {
            fontSize: '1rem',
            lineHeight: '1.1875',
        },
        cardHeaderButton: {
            textTransform: 'none',
            /* Sprout / Hyperlink */
            color: '#497EE7',
        },
        cardContentRoot: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        cardDivider: {
            marginTop: 0,
            marginBottom: theme.spacing(1)
        },
        dashboardButton: {
            backgroundColor: '#fff',
            borderRadius: 8,
            borderColor: '#C6D3DA',
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16)",
            textTransform: 'capitalize',
            fontSize: 16,
            '&:hover': {
                backgroundColor: '#fff'
            }
        }
    }
})