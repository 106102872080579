import React from 'react'
import { useStyles } from './styles'
import { Divider, Grid, Hidden } from '@material-ui/core'

export default function BusinessOwner({ owner, getAddress }: any) {
    const { first_name, last_name, address } = owner
    const classes = useStyles()
    return (
        <div className={classes.businessOwner}>
            <Grid container spacing={0} alignItems="flex-start" classes={{ root: classes.fieldsContainer }}>
                <Grid item container md={3} xs={12} classes={{ root: classes.businessOwnerfield }}>
                    <Grid item md={12} xs={6}>Owner Name</Grid>
                    <Grid item md={12} xs={6} classes={{ root: classes.value, "grid-xs-6": classes.customDivider }}>
                        {first_name} {last_name}
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Divider orientation="horizontal" classes={{ root: classes.horizontalDivider }} />
                </Hidden>
                <Hidden smDown>
                    <Divider orientation="vertical" flexItem variant="inset" />
                </Hidden>
                <Grid item container md={3} xs={12} classes={{ root: classes.businessOwnerfield }}>
                    <Grid item md={12} xs={6}>% of Ownership</Grid>
                    <Grid item md={12} xs={6} classes={{ root: classes.value, "grid-xs-6": classes.customDivider }}>100</Grid>
                </Grid>
                <Hidden mdUp>
                    <Divider orientation="horizontal" classes={{ root: classes.horizontalDivider }} />
                </Hidden>
                <Hidden smDown>
                    <Divider orientation="vertical" flexItem variant="inset" />
                </Hidden>
                <Grid item container md={3} xs={12} classes={{ root: classes.businessOwnerfield }}>
                    <Grid item md={12} xs={6}>SSN</Grid>
                    <Grid item md={12} xs={6} classes={{ root: classes.value, "grid-xs-6": classes.customDivider }}>n/a</Grid>
                </Grid>
            </Grid>
            <Divider orientation="horizontal" classes={{ root: classes.businessOwnerAddressDivider }} />
            <div className={classes.businessOwnerfield}>
                Address <div className={classes.value}>{getAddress(address)}</div>
            </div>
        </div>
    )
}
