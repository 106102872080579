import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    useCallback
} from 'react';
import { ActiveRouteHeadingContext } from '../../Routing';
import {
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import {
    uploadFilePOST,
    uploadedFilesGET,
    deleteFileDELETE
} from '../../services/ApiService';
import { File } from 'react-feather';
import { useStyles } from './styles';
import FilePreview from './FilePreview';
import CommonDialog from '../ui/CommonDialog';
import { withRouter } from 'react-router-dom';
import Axios, { CancelTokenSource } from 'axios';
import AppData from '../../services/AppData';

function Documents({ match }: any) {
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showAlert, setShowAlert] = useState<any>(false);
    const [uploadedFiles, setUpLoadedFiles] = useState<any[]>([]);
    const [currentFile, setCurrentFile] = useState<any>({});
    const [errorBody, setErrorBody] = useState<any>('');
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext);
    const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
    const classes = useStyles();

    const _documentAllowMime: string[] =
        AppData.config.file_center.allowed_mime_types;
    const _documentAllowTypes: string[] =
        AppData.config.file_center.allowed_extensions;
    const isFileTypeUnsupported = (files: any[]): boolean =>
        files.every(file => _isValidDocumentMime(file));
    const _isValidDocumentMime = function(item: any): boolean {
        let allowTypesSubst = _documentAllowTypes.map(t => {
            return t.substr(-4);
        });
        return item.type && item.type.substr(0, 5) !== 'like/'
            ? !_documentAllowMime.find(mime => mime.indexOf(item.type) !== -1)
            : !allowTypesSubst.find(
                  type => type.indexOf(item.name.substr(-4)) !== -1
              );
    };

    const loadAllFiles = useCallback(
        () => {
            const CancelToken = Axios.CancelToken;
            let source = CancelToken.source();
            setCancelTokens(prev => prev.concat(source));

            uploadedFilesGET(source.token)
                .then((res: any) => {
                    setUpLoadedFiles(res.items);
                    if (match.params.fileId) {
                        handleSelectFile(
                            res.items.find(
                                (file: any) => file.id === match.params.fileId
                            )
                        );
                    }
                    setLoadingFiles(false);
                    setUploadingFiles(false);
                })
                .catch(err => {
                    if (Axios.isCancel(err) || !err) {
                        return;
                    }
                    setErrorBody('Error while loading file(s).');
                    setLoadingFiles(false);
                    setUploadingFiles(false);
                    setShowAlert(true);
                });
        },
        [match]
    );

    const handleUploadFile = () => {
        let filesInput: any = document.getElementById('upload-button-file');
        let files: any[] = [...filesInput.files];
        if (isFileTypeUnsupported(files)) {
            setErrorBody(
                <Fragment>
                    <div>File(s) has unsupported format.</div>
                    <div>
                        Please upload only{' '}
                        <b>{_documentAllowTypes.join(', ')}</b>
                    </div>
                </Fragment>
            );
            setShowAlert(true);
            return;
        }

        setUploadingFiles(true);
        const CancelToken = Axios.CancelToken;
        let source = CancelToken.source();
        setCancelTokens(prev => prev.concat(source));

        Promise.all(
            files.map(file => {
                let formData = new FormData();
                formData.append(
                    'path',
                    '/permanent_files/business_formation_documents'
                );
                formData.append('type', 'business');
                formData.append('files', file);
                return uploadFilePOST(formData, source.token);
            })
        )
            .then(res => {
                loadAllFiles();
            })
            .catch(err => {
                if (Axios.isCancel(err) || !err) {
                    return;
                }
                setErrorBody('Error while uploading file(s).');
                setUploadingFiles(false);
                setShowAlert(true);
            });
    };

    const handleSelectFile = (file: any) => {
        setCurrentFile(file);
        setModalOpen(true);
    };

    const handleClearFile = () => {
        setModalOpen(false);
        setCurrentFile({});
    };

    const handleConfirmDelete = (state: boolean) => {
        setConfirmDelete(state);
    };

    const handleDeleteFile = () => {
        const CancelToken = Axios.CancelToken;
        let source = CancelToken.source();
        setCancelTokens(prev => prev.concat(source));

        setConfirmDelete(false);
        handleClearFile();
        setLoadingFiles(true);
        deleteFileDELETE(currentFile.id, source.token)
            .then(() => {
                loadAllFiles();
            })
            .catch(err => {
                if (Axios.isCancel(err) || !err) {
                    return;
                }
                setErrorBody('Error while deleting file.');
                setLoadingFiles(false);
                setShowAlert(true);
            });
    };

    useEffect(() => () => cancelTokens.forEach(token => token.cancel()), [
        cancelTokens
    ]);

    useEffect(
        () => {
            setActiveRouteHeading('Business Formation Documents');
            loadAllFiles();
        },
        [setActiveRouteHeading, loadAllFiles]
    );

    return (
        <div className={classes.mainContainer}>
            <div className={classes.filesContainer}>
                <div className={classes.uploadButtonContainer}>
                    {uploadingFiles
                        ? <Button disabled>Uploading Files...</Button>
                        : <Fragment>
                              <input
                                  className={classes.uploadInput}
                                  id="upload-button-file"
                                  multiple
                                  type="file"
                                  onChange={handleUploadFile}
                              />
                              <label htmlFor="upload-button-file">
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                      classes={{ root: classes.uploadButton }}
                                      component="span"
                                  >
                                      Upload Documents
                                  </Button>
                              </label>
                          </Fragment>}
                </div>
                <div
                    style={{ flex: 1, overflow: 'auto' }}
                    className={loadingFiles ? classes.centerData : ''}
                >
                    {loadingFiles
                        ? <CircularProgress
                              variant="indeterminate"
                              className={classes.progressColorDark}
                          />
                        : uploadedFiles.length > 0
                          ? <List>
                                {uploadedFiles.map((file, index) =>
                                    <ListItem
                                        button
                                        disableGutters
                                        key={index}
                                        onClick={() => handleSelectFile(file)}
                                    >
                                        <ListItemIcon
                                            classes={{ root: classes.listIcon }}
                                        >
                                            <File />
                                        </ListItemIcon>
                                        <ListItemText primary={file.filename} />
                                    </ListItem>
                                )}
                            </List>
                          : <div className={classes.noDocs}>
                                No Documents Were Found
                            </div>}
                </div>
            </div>
            <div className={classes.previewContainer}>
                <div
                    className={
                        modalOpen ? classes.previewWithoutIcon : classes.preview
                    }
                >
                    <FilePreview
                        modalOpen={modalOpen}
                        currentFile={currentFile}
                        handleClearFile={handleClearFile}
                        handleConfirmDelete={handleConfirmDelete}
                    />
                    <CommonDialog
                        type="confirm"
                        open={confirmDelete}
                        title="Confirm Delete"
                        body="Do you really want to remove this document? This can not be undone"
                        cancelAction={() => handleConfirmDelete(false)}
                        confirmAction={handleDeleteFile}
                    />
                    <CommonDialog
                        type="alert"
                        open={showAlert}
                        title="Error"
                        body={errorBody}
                        cancelAction={setShowAlert}
                    />
                </div>
            </div>
        </div>
    );
}
export default withRouter(Documents);
