import React, { useState, useEffect, createContext } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import AppToolbar from './components/core/AppToolbar';
import AppDrawer from './components/core/AppDrawer';
import AuthInit from './components/auth/AuthInit';
import AppView from './components/core/AppView';
import { useStyles } from './styles';
import { getApiConfig } from './services/ApiService';
import AppData from './services/AppData';
import LoaderPage from './components/common/LoaderPage';
import { initData } from './services/AccountService';
import { useAuth } from './services/AuthService';

export const ActiveRouteHeadingContext = createContext<any>({});

export default function Routing() {
  return (
    <HashRouter basename="/">
      <Switch>
        <Route exact path="/">
          <AuthInit />
        </Route>
        <Route component={Protected} />
      </Switch>
    </HashRouter>
  );
}

function Protected() {
  const [loading, setLoading] = useState(true);
  const auth = useAuth().isLoggedIn();

  useEffect(
    () => {
      getApiConfig().then((res: any) => {
        if(auth) {
          initData();
        }
        AppData.setValue('config', res);
        setLoading(false);
      });
    },
    [auth]
  );

  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeRouteHeading, setActiveRouteHeading] = useState('');
  return !loading
    ? <div className={classes.rootDiv}>
        <ActiveRouteHeadingContext.Provider
          value={{
            activeRouteHeading,
            setActiveRouteHeading
          }}
        >
          <AppToolbar setDrawerOpen={setDrawerOpen} />
          <AppDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
          <AppView />
        </ActiveRouteHeadingContext.Provider>
      </div>
    : <LoaderPage />;
}
