import React from 'react'
import { Typography, makeStyles, Theme } from '@material-ui/core'
import { CheckCircle } from 'react-feather'
import AppData from '../../services/AppData'
import moment from 'moment';

export default function BusinessDetails({ completedDate }: { completedDate: Date }) {
    let business = AppData.getCurrentBusiness();
    const classes = makeStyles((theme: Theme) => {
        return {
            businessName: {
                color: '#33485C'
            },
            businessType: {
                color: '#818A91'
            },
            otherDetails: {
                display: 'flex',
                justifyContent: 'space-around',
                padding: '40px 0'
            },
            otherDetailsTitle: {
                fontSize: 12,
                color: '#818A91',
                fontWeight: 'bold'
            },
            businessState: {
                color: '#33485C',
                fontSize: 24
            },
            businessStatus: {
                color: '#01C168',
                fontWeight: 500,
                fontSize: 24,
                display: 'flex',
                alignItems: 'center'
            }
        }
    })()
    return (
        <div>
            <Typography
                variant="h4"
                align="center"
                classes={{ root: classes.businessName }}
            >
                {business.name}
            </Typography>
            <Typography
                variant="h6"
                align="center"
                classes={{ root: classes.businessType }}
            >
                {AppData.config.business.types.find((t: any) => t.id === business.business_type)?.title || business.business_type}
            </Typography>
            <div className={classes.otherDetails}>
                <div>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        classes={{ root: classes.otherDetailsTitle }}
                    >
                        Registered State
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        classes={{ root: classes.businessState }}
                    >
                        {AppData.config.generic.states.find((s: any) => s.id === business.form_state)?.name || business.form_state}
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        classes={{ root: classes.otherDetailsTitle }}
                    >
                        Status
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        classes={{ root: classes.businessStatus }}
                    >
                        <CheckCircle size={19} style={{ marginRight: 5 }} />
                        Registered
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                    >
                        Completed {moment(completedDate).format('D MMM YYYY')}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
