import React, { useEffect, useContext } from 'react';
import { ActiveRouteHeadingContext } from '../../Routing';
import AppData from '../../services/AppData';
import BusinessOwner from './BusinessOwner';
import { useStyles } from './styles';
import { Divider, Hidden, Typography, Grid } from '@material-ui/core';
import SproutLogo from '../../assets/logo/Sprout.png';

export default function BusinessDetails() {
    const { setActiveRouteHeading } = useContext(ActiveRouteHeadingContext);
    const {
        name,
        business_type,
        industry,
        form_state,
        address,
        business_owners
    } = AppData.getCurrentBusiness();
    const classes = useStyles();
    useEffect(
        () => {
            setActiveRouteHeading('Business Details');
        },
        [setActiveRouteHeading]
    );

    const getAddress = (
        addressObj = {
            country: 'US',
            zip_code: '',
            address: '',
            city: ''
        }
    ) => {
        let address: string[] = [];
        if (addressObj.address) {
            address.push(addressObj.address);
        }
        if (addressObj.city) {
            address.push(addressObj.city);
        }
        if (addressObj.country) {
            address.push(
                AppData.config.generic.countries.find(
                    (country: any) => addressObj.country === country.id
                ).name
            );
        }
        if (addressObj.zip_code) {
            address.push(addressObj.zip_code);
        }
        return address.join(', ');
    };

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <Typography variant="h6" gutterBottom>
                    Business Details
                </Typography>
            </Hidden>
            <div className={classes.businessName}>
                {name}, {AppData.config.business.types.find(
                    (type: any) => business_type === type.id
                )?.title || business_type}
            </div>
            <Grid container spacing={0} alignItems="flex-start">
                <Grid
                    item
                    md={7}
                    xs={12}
                    classes={{ root: classes.detailsContainer }}
                >
                    <Grid
                        container
                        spacing={0}
                        alignItems="flex-start"
                        classes={{ root: classes.fieldsContainer }}
                    >
                        <Grid
                            item
                            container
                            md={3}
                            xs={12}
                            classes={{ root: classes.field }}
                        >
                            <Grid item md={12} xs={6}>
                                Industry
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={6}
                                classes={{
                                    root: classes.value,
                                    'grid-xs-6': classes.customDivider
                                }}
                            >
                                  {
                                    AppData.config.business.industries.find(
                                        (i: any) => industry === i.id
                                    )?.name || industry
                                }
                            </Grid>
                        </Grid>
                        <Hidden mdUp>
                            <Divider
                                orientation="horizontal"
                                classes={{ root: classes.horizontalDivider }}
                            />
                        </Hidden>
                        <Hidden smDown>
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="inset"
                            />
                        </Hidden>
                        <Grid
                            item
                            container
                            md={3}
                            xs={12}
                            classes={{
                                root: classes.field,
                                item: classes.customPaddingItem
                            }}
                        >
                            <Grid item md={12} xs={6}>
                                Business Formed
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={6}
                                classes={{
                                    root: classes.value,
                                    'grid-xs-6': classes.customDivider
                                }}
                            >
                                     {
                                    AppData.config.generic.states.find(
                                        (state: any) => form_state === state.id
                                    )?.name || form_state
                                }
                            </Grid>
                        </Grid>
                        <Hidden mdUp>
                            <Divider
                                orientation="horizontal"
                                classes={{ root: classes.horizontalDivider }}
                            />
                        </Hidden>
                        <Hidden smDown>
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="inset"
                            />
                        </Hidden>
                        <Grid
                            item
                            container
                            md={3}
                            xs={12}
                            classes={{
                                root: classes.field,
                                item: classes.customPaddingItem
                            }}
                        >
                            <Grid item md={12} xs={6}>
                                EIN
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={6}
                                classes={{
                                    root: classes.value,
                                    'grid-xs-6': classes.customDivider
                                }}
                            >
                                n/a
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{ margin: '10px 0' }}
                        orientation="horizontal"
                    />
                    <div className={classes.field}>
                        Address{' '}
                        <div className={classes.value}>
                            {getAddress(address)}
                        </div>
                    </div>
                    <Divider
                        style={{ margin: '10px 0' }}
                        orientation="horizontal"
                    />
                    <div className={classes.field}>
                        <div className={classes.value}>BUSINESS OWNERS</div>
                    </div>
                    <Divider
                        style={{ margin: '10px 0 0' }}
                        orientation="horizontal"
                    />
                    {business_owners.map((owner: any, index: number) =>
                        <BusinessOwner
                            key={index}
                            owner={owner}
                            getAddress={getAddress}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    md={5}
                    xs={12}
                    classes={{ root: classes.logoContainer }}
                >
                    <img
                        className={classes.logo}
                        alt="Business Logo"
                        src={SproutLogo}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
