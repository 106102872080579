import React, { useState, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import {
    ChevronDown,
    ChevronUp,
    User,
} from 'react-feather';
import MenuIcon from "../../assets/icons-svg/Menu.svg";
import SproutLogo from '../../assets/logo/Sprout.svg';

import { drawerWidth } from '../../styles';
import { makeStyles, Theme, IconButton, Hidden, Badge } from '@material-ui/core';
import { ActiveRouteHeadingContext } from "../../Routing";
import AppData from '../../services/AppData';
import { logout } from '../../services/AuthService';

export default function AppToolbar({ setDrawerOpen }: { setDrawerOpen: Function }) {
    const { activeRouteHeading } = useContext(ActiveRouteHeadingContext)
    const classes = makeStyles((theme: Theme) => {
        return {
            appBar: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    boxShadow: 'none',
                },
                backgroundColor: '#fff',
                boxShadow: '0 1px 2px #F9F9F9',
                color: theme.palette.text.primary,
            },
            appBarTitle: {
                flexGrow: 1,
            },
            avatarButton: {
                /* Sprout / BG / Blue */
                backgroundColor: '#F3F7FA',

                textTransform: 'none',
                fontWeight: 'normal',
                fontSize: '1rem',
            },
            avatarButtonIcon: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 8,
                paddingRight: 2,
            },
            listItemIconRoot: {
                minWidth: '44px',
            },
            menuButton: {
                marginRight: 0,
                [theme.breakpoints.up('md')]: {
                    marginRight: theme.spacing(2),
                    display: 'none',
                },
            },
            smToolbar: {
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            }
        }
    })();
    const [avatarEl, setAvatarEl] = useState<Element | null>(null);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Hidden mdUp implementation="css">
                <Toolbar classes={{ root: classes.smToolbar }}>
                    <Badge color="secondary" variant="dot" onClick={() => setDrawerOpen(true)}>
                        <img alt="Menu" src={MenuIcon} />
                    </Badge>
                    {/* <IconButton
                        className={classes.menuButton}
                    >
                        <img alt="Menu" src={MenuIcon} />
                    </IconButton> */}
                    <div>
                        <img src={SproutLogo} alt="Sprout logo" />
                    </div>
                    <IconButton
                        className={classes.menuButton}
                        onClick={(e) => { setAvatarEl(e.currentTarget); }}
                    >
                        <User />
                    </IconButton>
                </Toolbar>
            </Hidden>
            <Hidden smDown implementation="css">
                <Toolbar>
                    <Typography variant="h5" className={classes.appBarTitle}>{activeRouteHeading || 'Dashboard'}</Typography>
                    <Button variant="outlined" className={classes.avatarButton} onClick={(e) => { setAvatarEl(e.currentTarget); }}>
                        {AppData.getUser().first_name} {AppData.getUser().last_name}
                        <div className={classes.avatarButtonIcon}>
                            <PermIdentityIcon />
                        </div>
                        {!!avatarEl ? <ChevronUp /> : <ChevronDown />}
                    </Button>
                </Toolbar>
            </Hidden>
            <Menu
                open={!!avatarEl}
                anchorEl={avatarEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                onClose={(e) => { setAvatarEl(null) }}
                MenuListProps={{ disablePadding: true }}
                elevation={4}
            >
                <MenuItem component="a" href="https://sprout.business/privacy-policy/" target="_blank">
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}><DescriptionOutlinedIcon /></ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                </MenuItem>
                <MenuItem component="a" href="https://sprout.business/terms-service/" target="_blank">
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}><DescriptionOutlinedIcon /></ListItemIcon>
                    <ListItemText primary="Terms of Service" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={(e) => { logout(); }}>
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </MenuItem>
            </Menu>
        </AppBar>
    )
}
