import { user, accounts, personalAccount, currentAccount, currentAccountId, currentBusiness, currentBusinessId } from "./AccountService";

const AppData: any = {
  config: {},
  getUser: user,
  getAccounts: accounts,
  getPersonalAccount: personalAccount,
  getCurrentAccount: currentAccount,
  getCurrentAccountId: currentAccountId,
  getCurrentBusiness: currentBusiness,
  getCurrentBusinessId: currentBusinessId,
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  setValue: (key: string, c: any) => {
    AppData[key] = c;
  }
};

export default AppData;