import React, { Fragment, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
//import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import logo from '../../assets/logo/Sprout.svg';
import DashboardIcon from '../../assets/icons-svg/Dashboard.svg';
import DocumentsIcon from '../../assets/icons-svg/Documents.svg';
import MessagesIcon from '../../assets/icons-svg/Messages.svg';
import PurposeIcon from '../../assets/icons-svg/Purpose.svg';
import SettingsIcon from '../../assets/icons-svg/Settings.svg';
import { drawerWidth } from '../../styles';
import { makeStyles, Theme, Hidden, Menu, MenuItem } from '@material-ui/core';
import AppData from '../../services/AppData';
import { ChevronDown, ChevronUp } from 'react-feather';

export default function AppDrawer(props: { drawerOpen: boolean, setDrawerOpen: Function }) {
    const classes = makeStyles((theme: Theme) => {
        return {
            businessButtonRoot: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            businessButton: {
                /* Sprout / BG / Blue */
                background: '#F3F7FA',

                /* 1800Accountant / Secondary / Rule Color */
                border: '1px solid #C6D3DA',
                boxSizing: 'border-box',
                borderRadius: '4px',

                marginBottom: theme.spacing(1),
            },
            businessDropdownOpen: {
                borderBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
            },
            businessDropdownClose: {
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                textSize: '14px',
            },
            drawerPaper: {
                width: drawerWidth,
            },
            logoRoot: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '64px',
                ...theme.mixins.toolbar,
            },
            listItemText: {
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
            },
            messagesUnread: {
                height: '20px !important',
                color: '#ffffff !important',
                backgroundColor: theme.palette.warning.dark + ' !important',
                fontWeight: 'bold',
                cursor: 'pointer !important',
            },
            messagesUnreadLabel: {
                padding: '3px 10px !important',
            },
            navListItemGutters: {
                paddingLeft: 'calc(1.75rem + 1px)',
            },
            navListItemIconRoot: {
                minWidth: '32px',
            },
            listItemRoot: {
                //paddingLeft: '0.75rem',
                //borderLeft: '0.25rem solid #01C168',
                //borderTopLeftRadius: '2px',
                //borderBottomLeftRadius: '2px',
                //// Overriding backgroundColors due to root and selected combination. Maybe there is a better workaround
                backgroundColor: '#fff !important',
                '&:hover': {
                    backgroundColor: theme.palette.action.selected + ' !important',
                }
            },
            selectedNavIcon: {
                position: 'absolute',
                top: 0,
                left: theme.spacing(1),
                width: '4px',
                height: '36px',
                backgroundColor: '#01c168',
                borderRadius: '2px',
            },
            businessDropdownPaper: {
                left: `12px !important`,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                border: '1px solid #C6D3DA',
                borderTop: 0
            },
            businessDropdownList: {
                padding: 0,
                width: 214,
                backgroundColor: '#E6EEF5'
            },
            businessDropdownListItem: {
                paddingTop: 4,
                paddingBottom: 4
            }
        }
    })();
    const [businessEl, setBusinessEl] = useState<null | HTMLElement>();

    const drawerContent = (
        <Fragment>
            <div className={classes.logoRoot}>
                <img src={logo} alt="Sprout logo" />
            </div>
            <List component="nav">
                <div className={classes.businessButtonRoot}>
                    <ListItem
                        button
                        dense
                        classes={{ root: !!businessEl ? classes.businessDropdownOpen : classes.businessDropdownClose }}
                        className={classes.businessButton}
                        onClick={(event: any) => setBusinessEl(event.currentTarget)}
                    >
                        <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                            <img alt="Business Icon" src={PurposeIcon} />
                        </ListItemIcon>
                        <ListItemText primary={AppData.getCurrentBusiness().name} primaryTypographyProps={{ className: classes.listItemText }} />
                        {!!businessEl ? <ChevronUp /> : <ChevronDown />}
                    </ListItem>
                </div>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: "left",
                    }}
                    anchorEl={businessEl}
                    open={Boolean(businessEl)}
                    onClose={() => setBusinessEl(null)}
                    classes={{ list: classes.businessDropdownList, paper: classes.businessDropdownPaper }}
                    MenuListProps={{ disablePadding: true }}
                >
                    <MenuItem
                        component={Link}
                        to="/business-details"
                        dense
                        classes={{ root: classes.businessDropdownListItem }}
                    >
                        <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                            <img alt="Settings Icon" src={SettingsIcon} />
                        </ListItemIcon>
                        <ListItemText primary="Details" />
                    </MenuItem>
                </Menu>
                <ListItem component={Link} to="/dashboard" button dense classes={{ root: classes.listItemRoot, gutters: classes.navListItemGutters }}>
                    {useRouteMatch({ path: '/dashboard', exact: true }) ? <div className={classes.selectedNavIcon}></div> : null}
                    <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                        <img alt="Dashboard Icon" src={DashboardIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" primaryTypographyProps={{ className: classes.listItemText }} />
                </ListItem>
                <ListItem component={Link} to="/documents" button dense classes={{ root: classes.listItemRoot, gutters: classes.navListItemGutters }}>
                    {useRouteMatch({ path: '/documents' }) ? <div className={classes.selectedNavIcon}></div> : null}
                    <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                        <img alt="Documents Icon" src={DocumentsIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Documents" primaryTypographyProps={{ className: classes.listItemText }} />
                </ListItem>
                <ListItem component={Link} to="/message_center" button dense classes={{ root: classes.listItemRoot, gutters: classes.navListItemGutters }}>
                    {useRouteMatch({ path: '/message_center' }) ? <div className={classes.selectedNavIcon}></div> : null}
                    <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                        <img alt="Messages Icon" src={MessagesIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Messages" primaryTypographyProps={{ className: classes.listItemText }} />
                    {/* <Chip label="3" size="small" classes={{ root: classes.messagesUnread, labelSmall: classes.messagesUnreadLabel }} /> */}
                </ListItem>
            </List>
        </Fragment>
    );

    return (
        <Fragment>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    open={props.drawerOpen}
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    anchor="left"
                    onClose={() => props.setDrawerOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawerPaper }} anchor="left">
                    {drawerContent}
                </Drawer>
            </Hidden>
        </Fragment >
    )
}
