import { useAuth, authorize, checkAuth } from "../../services/AuthService";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import LoaderPage from "../common/LoaderPage";


export default function AuthInit() {
  const auth = useAuth();
  let query = useQuery();
  let params: any = {};
  query.forEach((v, k) => {
    params[k] = v;
  });

  if (!auth.isLoggedIn()) {
    initAuth(params, auth);
    return <LoaderPage />;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/dashboard'
        }}
      />
    );
  }
}

function initAuth(params: any, auth: any) {
  if (params.access_token) {
    authorize(params.access_token, params.expires_in).then(
      redirectToPreviousPage.bind(null, params),
      checkAuth
    );
  } else if (auth.isLoggedIn()) {
    return <Redirect to={params.back_uri ? params.back_uri : '/dashboard'} />;
  } else {
    checkAuth(params.back_uri || '');
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function redirectToPreviousPage(params: any) {
  if (params.access_token) {
    //prevent reload to dashboard if page reloaded after setting business
    var back_uri = params.back_uri
      ? window.decodeURIComponent(params.back_uri)
      : '/dashboard';
    window.location.href = '#' + back_uri;
  }
}
