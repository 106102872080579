import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useProgressStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 130,
            width: 130,
            position: 'relative'
        },
        innerBorder: {
            height: 110,
            width: 110,
            position: 'absolute'
        },
        circleProgressLabel: {
            textAlign: 'center',
            //position: 'relative',
            //left: '50%',
            //transform: 'translateX(-50%)',
        },
        circleProgressLabelPrimary: {
            fontSize: '1.5rem',
        },
        circleProgressLabelSecondary: {
            fontSize: '0.75rem',
            fontWeight: 'bold',
            /* Sprout / Grey */
            color: '#818A91',
        },
        commonBorder: {
            borderRadius: 70,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: '#01C168',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        progressColorLight: {
            color: '#BCF2DB',
            // strokeLinecap: 'round',
            position: 'absolute'
        },
        progressColorDark: {
            color: '#01C168',
            position: 'absolute'
        }
    }),
);

export default function DashboardProgressCircle(props: { progress: number }) {
    const progressClasses = useProgressStyles();
    const { progress } = props;

    return (
        <div className={`${progressClasses.root} ${progressClasses.commonBorder}`}>
            <div className={`${progressClasses.innerBorder} ${progressClasses.commonBorder}`}>
                <div className={progressClasses.circleProgressLabel}>
                    <div className={progressClasses.circleProgressLabelPrimary}>{progress}&#37;</div>
                    <div className={progressClasses.circleProgressLabelSecondary}>Complete</div>
                </div>
            </div>

            <CircularProgress
                variant="static"
                className={progressClasses.progressColorDark}
                value={-0.5}
                thickness={2.8}
                size={'100%'}
            />
            <CircularProgress
                variant="static"
                className={progressClasses.progressColorDark}
                value={progress + 0.5}
                thickness={2.8}
                size={'100%'}
            />
            <CircularProgress
                variant="static"
                className={progressClasses.progressColorLight}
                value={progress}
                thickness={2.71}
                size={'100%'}
            />
        </div>
    );
}